// Start | for demo

@import 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.3.2/css/simple-line-icons.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css';
@font-face {
  font-family: 'GraphikTh';
  font-display: swap;
  src: url('/static/fonts/graphikth-regular.woff') format('woff'), url('/static/fonts/graphikth-regular.woff2') format('woff2'), url('/static/fonts/graphikth-regular.eot') format('eot');
}
@font-face {
  font-family: 'GraphikThBold';
  font-display: swap;
  src: url('/static/fonts/graphikth-semibold.woff') format('woff'), url('/static/fonts/graphikth-semibold.woff2') format('woff2'), url('/static/fonts/graphikth-semibold.eot') format('eot');
}
@font-face {
  font-family: 'GraphikThThin';
  font-display: swap;
  src: url('/static/fonts/graphikth-thin.woff') format('woff'), url('/static/fonts/graphikth-thin.woff2') format('woff2'), url('/static/fonts/graphikth-thin.eot') format('eot');
}

[data-theme='nationtv'] {
  --primary-color: #194588 !important;
  --primary-color-new: #27398B !important;
  --secondary-color: #0d6efd !important;
  --tertiary-color: #0d6efd !important;
  --quaternary-color: #3b6bb5 !important;
  --text-color: #fff !important;
  --primary-gradient: linear-gradient(270deg, #4377ca 0%, #1e3256 100%) !important;
  --secondary-gradient: linear-gradient(270deg, #4377ca 0%, #1e3256 100%) !important;
  --border-gradient: linear-gradient(90deg, #6ba3d2 1.56%, #6ba3d2 26.17%, #4377ca 26.17%, #4377ca 50.77%, #194588 50.78%, #194588 75.38%, #1e3256 75.39%, #1e3256 100%) !important;
}
[data-theme='light'] {
  --primary-color: #194588 !important;
  --primary-color-new: #27398B !important;
  --secondary-color: #0d6efd !important;
  --tertiary-color: #0d6efd !important;
  --quaternary-color: #3b6bb5 !important;
  --text-color: #fff !important;
  --primary-gradient: linear-gradient(270deg, #4377ca 0%, #1e3256 100%) !important;
  --secondary-gradient: linear-gradient(270deg, #4377ca 0%, #1e3256 100%) !important;
  --border-gradient: linear-gradient(90deg, #6ba3d2 1.56%, #6ba3d2 26.17%, #4377ca 26.17%, #4377ca 50.77%, #194588 50.78%, #194588 75.38%, #1e3256 75.39%, #1e3256 100%) !important;
}
