*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  overflow-x: clip;
  font-family: var(--front-primary), sans-serif;
  @media (max-width: 690px) {
    overflow-x: unset;
    font-family: var(--front-thin), sans-serif;
  }
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(247, 247, 247, 0.9);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    //background-color: var(--primary-color);
    background-color: #303030;
  }
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }
  a {
    outline: none;
    text-decoration: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.--1 {
    -webkit-line-clamp: 1;
  }
  &.--2 {
    -webkit-line-clamp: 2;
  }
  &.--3 {
    -webkit-line-clamp: 3;
  }
  &.--4 {
    -webkit-line-clamp: 4;
  }
  &.--5 {
    -webkit-line-clamp: 5;
  }
  &.--6 {
    -webkit-line-clamp: 6;
  }
}

.container {
  max-width: 1300px !important;
  width: 100%;
  margin: auto;
  padding: 0 15px;
}

.background-default {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  max-width: 99vw;
  max-height: 99vh;
}

/* --------------------------- START : LANDING_PAGE --------------------------- */
.show-landing-page {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.svgll {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  max-width: 99vw;
  max-height: 99vh;
}
.section-politics {
  min-height: 680px;
}

.position-sticky-left-content {
  position: sticky;
  top: 97px;
  z-index: 14;
  @media (max-width: 1290px) {
    top: 150px;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 40%;
    right: 0;
    // transform: translate(0, -65%);
  }
}

.position-sticky-left-content-js {
  position: sticky;
  top: 97px;
  z-index: 14;
  @media (max-width: 992px) {
    top: 150px;
  }
  @media (max-width: 768px) {
    position: absolute;
    right: 0%;
    transform: translate(0, 0);
  }
}

.back-drop {
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 2px 21.38px,
    rgba(0, 0, 0, 0.19) 0px 32px 64px;
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
}
.video-related {
  max-width: 420px;
  width: 100%;
  margin: auto;
  .title {
    margin-top: 5px;
  }

  .card-video-h {
    position: relative;
    grid-template-columns: 1.5fr 2fr !important;
    .resolution-image {
      padding-top: 56.25%;
      img {
        margin-top: 0 !important;
      }
    }
    .card-detail {
      max-width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    .video-remore {
      display: none;
    }
  }
  // .card-video-h {
  //   grid-template-columns: 1fr;
  //   grid-template-areas: 'card-image' 'card-detail';

  // }
  // .card-image {
  //   // margin: 0;
  //   height: 80px;
  // }
}
.related-news-desktop {
  width: 420px;
  margin: auto;
  min-height: unset;
  max-width: 100%;
  // @media (max-width: 767px) {
  //   margin: -40px auto -10px auto;
  // }
  .block-item {
    cursor: pointer;
  }
}
.widget-content {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  min-height: 350px;
  &.--1 {
    min-height: 150px;
  }
  &.--2 {
    min-height: 250px;
  }

  .block-list {
    // .slick-current {
    //   max-width: 80% !important;
    // }
    // .slick-slider {
    //   padding: 0 3rem;
    //   @media (max-width: 768px) {
    //     padding: 0;
    //   }
    //   .slick-next,
    //   .slick-prev {
    //     svg {
    //       @media (max-width: 768px) {
    //         display: none;
    //       }
    //       backdrop-filter: none;
    //       background: transparent;
    //       polyline {
    //         color: var(--primary-color-new);
    //       }
    //     }
    //   }
    // }
    &.--vertical {
      .card-video-h {
        flex-direction: row;
        position: relative;
        grid-template-columns: 1.5fr 2fr;

        .video-remore {
          display: none;
        }
        .card-image {
          width: 100%;
          picture {
            padding-top: 60%;
            img {
              margin-top: 0;
            }
          }

          @media (max-width: 768px) {
            width: 80%;
          }
          @media (max-width: 475px) {
            width: 100%;
          }
        }
      }
      @media (max-width: 475px) {
        .card-video-h {
          .video-remore {
            display: none;
          }
        }
        .slick-track {
          .slick-slide {
            position: relative;
            left: -2.5rem;
          }
          .slick-active {
            position: relative;
            left: 0rem;
          }
        }
      }
    }
    li {
      margin-bottom: 10px;
    }
  }
  .widget-content-title {
    min-height: 42px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 2px dotted var(--primary-color-new);
    margin-bottom: 20px;
    align-items: baseline;

    h3 {
      background: var(--primary-color-new);
      color: #fff;
      width: fit-content;
      padding: 13px;
      border-radius: 10px 10px 0 0px;
      font-size: 14px;
      margin-bottom: -2px;
    }
    .related-news {
      color: var(--primary-color-new);
      background: unset;
      width: fit-content;
      padding: 13px;
      padding-left: 0;
      border-radius: 10px 10px 0 0px;
      font-size: 16px;
      margin-bottom: -2px;
    }
  }
}
.block-content {
  &.--small {
    .block-item {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eeeeee;

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  .block-list {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eeeeee;

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}

#iz-news-hub-discovr-outer-wrapper.iz-news-hub-floating-icon-container-bottom-right {
  bottom: 50vh !important;
}
